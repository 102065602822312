#add-r .field-label .label{
    text-align: left;
}
#add-r h4{
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
}

#addEditForm{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 25px 40px;
    border-radius: 15px;
}
.branchName{
    margin-right: 20px;
}
.select1{
    border: 1px solid transparent !important;
    border-color: #dbdbdb !important;
    border-radius: 4px;
  }
  .bordered-special1 {
    display: flex;
    border: 1px solid #dbdbdb;
    padding: 5px 16px;
    border-radius: 5px;
  }
  .field2{
    text-align: right;
  }
  .errorMsg{
    margin-top: 10px;
    color: red;
    font-size: 14px;

  }
  .tag{
    padding: 0 10px;
    font-size: 12px !important;
    border-radius: 5px;
    border: transparent;
    color: green !important;
    background-color: #ccffcc!important;
    width: 57px !important;
    letter-spacing: .5px;
  }
  .tag2{
    padding: 0 10px;
    font-size: 12px !important;
    border-radius: 5px;
    border: transparent;
    color: red !important;
    background-color: #ffd7cc!important;
    width: 80px !important;
    letter-spacing: .5px;
    text-align: center !important;
  }
/* 
  .ant-tag {
    padding: 0 10px;
    font-size: 15px;
    border-radius: 4px;
} */